
















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAccommodationList: () =>
      import(
        "@/components/organisms/event/modules/accommodationModule/o-accommodation-list.vue"
      ),
  },
  setup() {
    return {};
  },
});
